.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}



.App-header {
	background: rgb(58,69,91);
	background: radial-gradient(circle, rgba(58,69,91,1) 0%, rgba(21,25,31,1) 100%);

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.short-link-area{
	cursor: pointer;
}

.short-link-area:hover{
	background: beige;
	text-decoration: underline;
}

.mytoast{
	position: absolute !important;
	top: 70% !important;
	left: 50% !important;
	transform: translate(-50%, -50%);
	
	-moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);

}